/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'comments': {
    width: 16,
    height: 15,
    viewBox: '0 0 16 15',
    data: '<path pid="0" d="M13.754 0H2.398a1.351 1.351 0 00-1 .4 1.351 1.351 0 00-.4 1v12.748l2.828-2.825h9.928c.377.01.74-.14 1-.415.264-.27.408-.636.4-1.013v-8.5a1.351 1.351 0 00-.4-1 1.351 1.351 0 00-1-.394z" _fill="#9FB0C9"/>'
  }
})
